/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * 替换为0
 */
export function replZero(vfrom, field) {
  if (vfrom[field] == "") {
    vfrom[field] = 0;
  }
}

///过滤掉登录或者退出
export function filterUrl(url) {
  if (/(logout|login)/.test(url)) {
    return "/";
  }

  if (!url) {
    return "";
  }

  return url;
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
  return urlregex.test(textval);
}
